import styled from "styled-components"
import { Link, Variables } from "@life-without-barriers/react-components"
const {
  black,
  youthPinkMedium,
  youthPinkDark,
  youthPinkXDark,
  youthPinkXXXLight,
  greyLight,
  greyXXXLight
} = Variables

export const sharedPinkHover = `
  &:hover {
    color: ${youthPinkMedium};
  }
`

export const PinkHoverLink = styled(Link)`
  ${sharedPinkHover};
`

export const BreadCrumbLink = styled(Link)`
  color: ${black};
  &:hover,
  &:focus {
    color: ${youthPinkXDark};
  }
`

export const BreadCrumbAnchor = styled(Link)`
  color: ${black};
  &:hover,
  &:focus {
    color: ${youthPinkXDark};
  }
`

export const ContentfulCopy = styled.div`
  font-size: 18px;
  line-height: 27px;

  p {
    margin: 0;
  }

  h1 {
    font-size: 32px;
    line-height: 1.313;
  }

  h2 {
    font-size: 28px;
    line-height: 1.313;
  }

  h3 {
    font-size: 20px;
    line-height: 1.313;
  }

  h4,
  h5 {
    font-size: 18px;
    line-height: 1.313;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
  }

  blockquote {
    color: ${youthPinkMedium};
    font-size: 28px;
    font-weight: 200;
    line-height: 1.313;
    margin: 2rem 0;
    text-align: center;
  }

  ul,
  ol {
    margin: 1rem 0 0 0;
    padding: 0 0 0 1rem;
  }

  ul li,
  ol li {
    margin-top: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 2rem;
  }

  p {
    margin-top: 1rem;
  }

  table {
    border-collapse: collapse;
    margin: 2rem 0;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    background-color: ${youthPinkXXXLight};
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem 0.75rem;
    margin: 0;
    vertical-align: top;
  }

  tr:nth-child(even) {
    background-color: ${greyXXXLight};
  }

  /* 
  * remove top margin from 1st immediate child inside this component
  * regardless of what it is.
  */

  > *:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: 30em) {
    font-size: 16px;
    line-height: 1.5;

    h1 {
      font-size: 48px;
      line-height: 54px;
    }

    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    h3 {
      font-size: 24px;
      line-height: 36px;
    }

    h4,
    h5 {
      font-size: 21px;
      line-height: 1.313;
    }

    blockquote {
      font-size: 32px;
      line-height: 1.313;
    }

    ul,
    ol {
      padding: 0 0 0 2rem;
    }
  }
`

export const InlineLinkGroup = styled.div`
  ul,
  ol {
    background-color: ${greyXXXLight};
    overflow: hidden;
    list-style: none;
    margin: 3rem 0 0 0;
    padding: 0 1rem;
  }

  ul li,
  ol li {
    margin: 0;
  }

  ul li a,
  ol li a {
    display: inline-block;
    padding: 1rem 2rem 1rem 0;
    position: relative;
    text-align: left;
  }

  ol li a:before,
  ul li a:before {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='8' viewBox='0 0 34 58'><path d='M9.82 1.583c2.09 2.04 22.51 23.48 22.51 23.48a5.484 5.484 0 0 1 1.67 3.94c0 1.42-.56 2.85-1.67 3.94 0 0-20.42 21.44-22.51 23.47-2.09 2.04-5.85 2.18-8.08 0-2.23-2.17-2.41-5.21 0-7.87l18.74-19.54L1.74 9.463c-2.41-2.67-2.23-5.71 0-7.88 2.23-2.18 5.99-2.04 8.08 0' fill='#d33391'/></svg>");
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  /* hover style */
  ul li a:hover,
  ol li a:hover,
  ul li a:focus,
  ol li a:focus {
    color: ${youthPinkDark};
  }

  ol li a:hover:before,
  ul li a:hover:before,
  ol li a:focus:before,
  ul li a:focus:before {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='8' viewBox='0 0 34 58'><path d='M9.82 1.583c2.09 2.04 22.51 23.48 22.51 23.48a5.484 5.484 0 0 1 1.67 3.94c0 1.42-.56 2.85-1.67 3.94 0 0-20.42 21.44-22.51 23.47-2.09 2.04-5.85 2.18-8.08 0-2.23-2.17-2.41-5.21 0-7.87l18.74-19.54L1.74 9.463c-2.41-2.67-2.23-5.71 0-7.88 2.23-2.18 5.99-2.04 8.08 0' fill='#9a1b66'/></svg>");
  }

  @media screen and (min-width: 30em) {
    ul,
    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    ul li a,
    ol li a {
      padding: 1rem 2rem 1rem 1rem;
    }

    ul li a:after,
    ol li a:after {
      background: ${greyLight};
      content: "";
      height: 40%;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    ul li:nth-child(odd) a:after,
    ol li:nth-child(odd) a:after {
      background: ${greyLight};
      content: "";
      height: 40%;
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    ol li:last-child a:after,
    ul li:last-child a:after {
      display: none;
    }
  }

  @media screen and (min-width: 60em) {
    ul li a,
    ol li a {
      padding: 1.5rem 2.8rem 1.5rem 1.2rem;
    }
  }
`
