import * as React from "react"
import { graphql } from "gatsby"
import { PreStyledComponents } from "@life-without-barriers/react-components"

import {
  BreadCrumbLink,
  BreadCrumbAnchor,
  ContentfulCopy,
  InlineLinkGroup
} from "../../components/carer-guide/localStyledComponents"
import { Site, SiteMetaData } from "@life-without-barriers/gatsby-common"
import MainSiteBanner from "../../components/carer-guide/MainSiteBanner"
import Layout from "../../components/carer-guide/Layout"
import WrappedHelmet from "../../components/carer-guide/WrappedHelmet"

const { Container } = PreStyledComponents

interface BreadCrumbProps {
  current: string
  category: {
    categoryTitle: string
    slug: string
  }
  siteMetadata: SiteMetaData
}

const BreadCrumb = ({ current, category, siteMetadata }: BreadCrumbProps) => (
  <div className="inline-flex flex-wrap mt4 mt6-ns ph3 pv3 br2 lh-copy bg-lwb-theme-xxx-light t-topic-breadcrumbs">
    <BreadCrumbAnchor
      className="black normal nowrap"
      title="Foster care"
      to={`${siteMetadata.siteUrl}/foster-care`}
    >
      Foster care
    </BreadCrumbAnchor>
    <div className="mh2">/</div>
    <BreadCrumbLink
      className="black normal nowrap"
      title="Carer guide"
      to="/foster-care/support/carer-guide"
    >
      Carer guide
    </BreadCrumbLink>
    <div className="mh2">/</div>
    <BreadCrumbLink
      className="black normal"
      title={category.categoryTitle}
      to={`/foster-care/support/carer-guide/category/${category.slug}`}
    >
      {category.categoryTitle}
    </BreadCrumbLink>
    <div className="mh2">/</div>
    <div>{current}</div>
  </div>
)

interface TopicPageProps {
  location: { pathname: string }
  data: {
    allContentfulCarerGuideTopic: {
      edges: [
        {
          node: {
            id: string
            slug: string
            title: string
            category: [{ categoryTitle: string; slug: string }]
            content: {
              id: string
              childMarkdownRemark: {
                html: string
              }
            }
            inlineLinkGroup: {
              id: string
              childMarkdownRemark: {
                html: string
              }
            }
          }
        }
      ]
    }
    site: Site
  }
}

const TopicPage = (props: TopicPageProps) => {
  const { node } = props.data.allContentfulCarerGuideTopic.edges[0]
  const {
    site: { siteMetadata }
  } = props.data
  return (
    <Layout
      siteMetadata={props.data.site.siteMetadata}
      location={props.location}
    >
      <div className="pb5">
        <WrappedHelmet
          title={`${node.title} - Carer guide | ${siteMetadata.title}`}
          description={`Support topics for the ${node.title} category`}
          siteUrl={siteMetadata.siteUrl}
          path={props.location.pathname}
        />

        <Container>
          <div className="ph3 ph4-ns ph6-l overflow-hidden">
            {node.category !== null && node.category.length && (
              <BreadCrumb
                current={node.title}
                category={node.category[0]}
                siteMetadata={siteMetadata}
              />
            )}
            <h1 className="ts-display-1 fw8 ma0 mt4 t-topic-title">
              {node.title}
            </h1>
            <ContentfulCopy
              className="mt3 mt4-l"
              dangerouslySetInnerHTML={{
                __html: node.content.childMarkdownRemark.html
              }}
            />
            {node.inlineLinkGroup !== null && (
              <InlineLinkGroup
                className="mt4-ns"
                dangerouslySetInnerHTML={{
                  __html: node.inlineLinkGroup.childMarkdownRemark.html
                }}
              />
            )}
          </div>
          <div className="pt4">
            <MainSiteBanner siteMetadata={siteMetadata} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TopicQuery($topicTitle: String) {
    allContentfulCarerGuideTopic(filter: { title: { eq: $topicTitle } }) {
      edges {
        node {
          id
          slug
          title
          category: carer_guide_category {
            categoryTitle
            slug
          }
          content {
            id
            childMarkdownRemark {
              html
            }
          }
          inlineLinkGroup {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default TopicPage
