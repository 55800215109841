import * as React from "react"

import { PinkHoverLink } from "../../components/carer-guide/localStyledComponents"
import { SiteMetaData } from "@life-without-barriers/gatsby-common"

interface MainSiteBannerProps {
  siteMetadata: SiteMetaData
}

const MainSiteBanner = ({ siteMetadata: { siteUrl } }: MainSiteBannerProps) => (
  <div className="mh3 mh4-m mh6-l mt4 br2 lwb-shadow overflow-hidden bg-lwb-white">
    <div className="bg-lwb-theme-medium pt2 pb1" />
    <div className="pa3 pa4-l">
      <div className="ts-display-4 fw8">Want to become a carer?</div>
      <div className="pt3">
        To become a foster carer your ability to care and nurture a child is
        what really matters.
      </div>
      <div className="pt2">
        To learn more, visit the{" "}
        <PinkHoverLink
          className="link no-underline"
          to={`${siteUrl}/foster-care/`}
        >
          LWB foster care website
        </PinkHoverLink>
      </div>
    </div>
  </div>
)

export default MainSiteBanner
